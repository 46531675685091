<template>
  <div class="admin-content">
    <FadeTransition
      :duration="200"
      mode="out-in"
    >
      <!-- your content here -->
      <router-view :key="$route.fullPath" />
    </FadeTransition>
  </div>
</template>
<script>
import { FadeTransition } from 'vue2-transitions';
export default {
  components: {
    FadeTransition
  }
};
</script>
<style>
.admin-content {
  //background-color: beige;
}
</style>
