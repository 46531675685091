export const FocusFirstInvalidDirective = {
  // called before bound element's attributes
  // or event listeners are applied
  bind(el, binding, vnode, prevVnode) {
    // console.debug('on bind', el, el.id, binding.value);
    el.addEventListener('submit', () => {
      document.querySelector( '#' + el.id + ' input.is-invalid')?.focus();
    });
  },
  inserted(el, binding, vnode, prevVnode) {
    // console.log('inserted', el, binding, vnode, prevVnode);
  },
  update(el, binding, vnode, prevVnode) {
    // console.log('on update', el, binding, vnode, prevVnode);
  },
  componentUpdated(el, binding, vnode, prevVnode) {
    // console.debug('updated', el.id, binding.value, binding.oldValue);
  },
  unbind(el, binding, vnode, prevVnode) {
    // console.log('on unbind', el, binding, vnode, prevVnode);
  }
};

export const FallbackImageDirective = {
  inserted: function (el, binding) {
    try {
      const { value } = binding;
      const loader = '/img/no_image_found.png';
      const fallBackImage = '/img/no_image_found.png';
      const img = new Image();
      let loading = loader;
      let error = fallBackImage;
      let original = el.src;
      if (typeof value === 'string') {
        loading = value;
        error = value;
      }
      if (value instanceof Object) {
        // loading = value.imageLoader || loader;
        error = value.fallBackImage || fallBackImage;
      }
      img.src = original;
      // el.src = loading;
      img.onload = () => {
        el.src = original;
      };
      img.onerror = () => {
        el.src = error;
      };
    } catch (e) {
      console.log(e);
    }
  },
  update: function (el, binding) {
    try {
      const { value } = binding;
      const loader = '/img/no_image_found.png';
      const fallBackImage = '/img/no_image_found.png';
      const img = new Image();
      let loading = loader;
      let error = fallBackImage;
      let original = el.src;
      if (typeof value === 'string') {
        loading = value;
        error = value;
      }
      if (value instanceof Object) {
        // loading = value.imageLoader || loader;
        error = value.fallBackImage || fallBackImage;
      }
      img.src = original;
      // el.src = loading;
      img.onload = () => {
        el.src = original;
      };
      img.onerror = () => {
        el.src = error;
      };
    } catch (e) {
      console.log(e);
    }
  }
};

