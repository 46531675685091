import ValidationPlugin from '@/util/validation';

const FilterPlugin = {
  // eslint-disable-next-line no-unused-vars
  install(Vue, options) {
    Vue.filter('toStringClass', function (classStr, classObj) {
      return classStr + ' ' + Object.entries(classObj).filter(e => e[1] === true).map(e => e[0]).join(' ');
    });
  }
};

export default FilterPlugin;
