<template>
  <div class="content">
    <FadeTransition
      :duration="200"
      mode="out-in"
    >
      <!-- your content here -->
      <router-view :key="$route.fullPath" />
    </FadeTransition>
  </div>
</template>

<script>
import { FadeTransition } from 'vue2-transitions';
import { API } from '@/util/api';
import { CUSTOMER_URLS } from '@/util/endpoints';

export default {
  name: 'CustomerContent',
  components: {
    FadeTransition
  },
  async mounted() {
    try {
      const programId = this.$store.getters['systemSetting/programID'];
      console.log(programId);
      if (programId) {
        const response = await API.get(CUSTOMER_URLS.GET_SYSTEM_SETTING, {
          headers: {
            ProgramID: programId
          }
        });
        await this.$store.dispatch('systemSetting/load', response.data);

      }
    } catch (e) {
      console.error(e);
    }
  }
};
</script>
