<template>
  <router-view />
</template>

<script>

export default {
};
</script>
<style lang="scss" src="./App.scss"></style>
