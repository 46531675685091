/*!

=========================================================
* BootstrapVue Argon Dashboard - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/bootstrap-vue-argon-dashboard
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Vue from 'vue';
import Vuex from 'vuex';
import DashboardPlugin from './plugins/dashboard-plugin';
import App from './App.vue';
// router setup
import router from './router';
// store setup
import store from './store';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import ValidationPlugin from '@/util/validation';
import FilterPlugin from '@/util/filters';
import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css';

import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {
  FallbackImageDirective,
  FocusFirstInvalidDirective,
} from '@/util/directives';
import ValidationFormInput from '@/components/ui/Inputs/ValidationFormInput';
import ValidationFormRadio from '@/components/ui/Inputs/ValidationFormRadio';
import ValidationFormTextarea
  from '@/components/ui/Inputs/ValidationFormTextarea';
import ValidationFormSelect from '@/components/ui/Inputs/ValidationFormSelect';
import ValidationFormFile from '@/components/ui/Inputs/ValidationFormFile';

// plugin setup
Vue.use(DashboardPlugin);

Vue.use(Vuex);

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(ValidationPlugin);
Vue.use(FilterPlugin);

Vue.directive('image-fallback', FallbackImageDirective);
Vue.directive('focus-first-invalid', FocusFirstInvalidDirective);
Vue.use(VueLoading);

Vue.component('ValidationFormInput', ValidationFormInput);
Vue.component('ValidationFormRadio', ValidationFormRadio);
Vue.component('ValidationFormSelect', ValidationFormSelect);
Vue.component('ValidationFormTextarea', ValidationFormTextarea);
Vue.component('ValidationFormFile', ValidationFormFile);

if (process.env.VUE_APP_ENV_TYPE === 'production') {
  Vue.config.devtools = false;
  Vue.config.debug = false;
  Vue.config.silent = true;
}

/* eslint-disable no-new */
const app = new Vue({
  el: '#app',
  render: h => h(App),
  router,
  store
});

export default app;
