import axios from 'axios';
import { AUTHENTICATION_URLS } from '@/util/endpoints';
import app from '@/main';
import {
  DANGER_TOAST_OPTION, INFO_TOAST_OPTION
} from '@/util/toast-options';
import store from '@/store';
import router from '@/router';
import {
  getAccessToken,
  getRefreshToken,
  isRememberMe,
  removeToken,
  setToken,
} from '@/util/storage-util';

const EXCLUDING_URLS = [AUTHENTICATION_URLS.ACCESS_TOKEN,
  AUTHENTICATION_URLS.REFRESH_TOKEN];

export const API = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  headers: {
    'Accept-Language': 'vi-VN',
  }
});

API.interceptors.request.use((request) => {
  // add auth header with jwt if account is logged in and request is to the api url
  const needAuthenticated = !request.url || (request.url
    && !EXCLUDING_URLS.includes(request.url));
  const accessToken = getAccessToken();
  if (needAuthenticated && request.headers && accessToken) {
    request.headers['Authorization'] = `Bearer ${accessToken}`;
  }
  // add program ID
  const programID = store.getters['systemSetting/programID'];
  if (programID) {
    request.headers['ProgramID'] = programID;
  }
  return request;
});

API.interceptors.response.use(
  function(response) {
    // Do something with response data
    return response;
  },
  async function(error) {
    if (AUTHENTICATION_URLS.REFRESH_TOKEN === error?.config?.url) {
      return Promise.reject(error);
    }
    const refreshToken = getRefreshToken();
    if (error?.response?.status === 401 && refreshToken) {
      try {
        if (!error.config._retried) {
          error.config._retried = true;
          const response = await API.post(AUTHENTICATION_URLS.REFRESH_TOKEN,
            { refreshToken: refreshToken });
          console.log('access token new', response.data.accessToken);
          setToken(response.data.accessToken, response.data.refreshToken, isRememberMe());
          return API.request({
            ...error.config,
            headers: {
              'Authorization': `Bearer ${response.data.accessToken}`,
            },
            data: (typeof error.config.data === 'string'
              && error.config.headers['Content-Type'] === 'application/json')
              ?
              JSON.parse(error.config.data) : error.config.data
          });
        }
      } catch (e) {
        console.error(e);
        app.$bvToast.toast(e?.response?.data?.message ?? (e?.message ?? 'Lỗi không xác định'), INFO_TOAST_OPTION);
      }
      removeToken();
      await store.dispatch('user/logout');
      setTimeout(() => {
        router.push({ path: '/auth/login' });
      }, DANGER_TOAST_OPTION.autoHideDelay / 2);
    } else {
      if (error?.response?.data instanceof Blob) {
        const file = error?.response?.data;
        if (file.type === 'application/json') {
          const errResObj = JSON.parse(await file.text());
          app.$bvToast.toast(errResObj.message, DANGER_TOAST_OPTION);
        } else {
          app.$bvToast.toast('Lỗi không xác định', DANGER_TOAST_OPTION);
        }
      } else {
        app.$bvToast.toast(error?.response?.data?.message ?? (error?.message
          ?? 'Lỗi không xác định'), DANGER_TOAST_OPTION);
      }
    }
    return Promise.reject(error);
  },
);
