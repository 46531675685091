<template>
  <ValidationProvider
    v-slot="{ classes, errors }"
    :vid="vid"
    :name="name"
    :rules="rules"
    :mode="mode"
  >
    <b-form-select
      id="input-5"
      :value="value"
      :options="items"
      :multiple="multiple"
      :size="size"
      :disabled="disabled"
      :class="customClass | toStringClass(classes)"
      @input="onInput"
      @change="onChange"
    >
      <template
        v-if="placeholder"
        #first
      >
        <b-form-select-option
          :value="null"
          :disabled="required"
        >
          {{ placeholder }}
        </b-form-select-option>
      </template>
    </b-form-select>
    <small
      class="text-danger text-break"
      style="white-space: normal"
    >
      {{ errors[0] }}
    </small>
  </ValidationProvider>
</template>

<script>
export default {
  name: 'ValidationFormSelect',
  filters: {
    toStringClass: function (classStr, classObj) {
      return classStr + ' ' + Object.entries(classObj).filter(e => e[1] === true).map(e => e[0]).join(' ');
    }
  },
  props: {
    id: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: 'sm'
    },
    vid: {
      type: String,
      default: ''
    },
    rules: {
      type: [String, Object],
      default: ''
    },
    mode: {
      type: String,
      default: 'aggressive'
    },
    items: {
      type: Array,
      required: true,
    },
    value: {
      type: [String, Number, Boolean, Array],
      required: false,
      default: () => null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    customStyle: {
      type: String,
      default: ''
    },
    customClass: {
      type: String,
      default: ''
    }
  },
  methods: {
    onInput(value) {
      this.$emit('input', value);
    },
    onChange(value) {
      this.$emit('change', value);
    }
  }
};
</script>

<style scoped>

</style>
