<template>
  <ValidationProvider
    v-slot="{ classes, errors }"
    :vid="vid"
    :name="name"
    :rules="rules"
    :mode="mode"
  >
    <b-form-input
      :id="id"
      :value="value"
      :size="size"
      :placeholder="placeholder"
      :maxlength="maxlength"
      :disabled="disabled"
      :style="customStyle"
      :class="customClass | toStringClass(classes)"
      @input="onInput"
      @change="onChange"
      @focus="onFocus"
      @blur="onBlur"
      @keypress="onKeypress"
    />
    <small
      class="form-text text-danger text-break"
      style="white-space: normal"
    >
      {{ errors[0] }}
    </small>
  </ValidationProvider>
</template>

<script>
export default {
  name: 'ValidationFormInput',
  props: {
    id: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: 'sm'
    },
    vid: {
      type: String,
      default: ''
    },
    rules: {
      type: [String, Object],
      default: ''
    },
    mode: {
      type: String,
      default: 'aggressive'
    },
    value: {
      type: [String, Number],
      required: true,
    },
    maxlength: {
      type: [String, Number],
      default: 255
    },
    disabled: {
      type: Boolean,
      default: false
    },
    customStyle: {
      type: String,
      default: ''
    },
    customClass: {
      type: String,
      default: ''
    }
  },
  methods: {
    onInput(value) {
      this.$emit('input', value);
    },
    onChange(value) {
      this.$emit('change', value);
    },
    onFocus(event) {
      this.$emit('focus', event);
    },
    onBlur(event) {
      this.$emit('blur', event);
    },
    onKeypress(event) {
      this.$emit('keypress', event);
    }
  }
};
</script>

<style scoped>

</style>
