export const DEFAULT_DIALOG_OPTION = {
  title: 'Xác nhận',
  size: 'md',
  buttonSize: 'sm',
  okVariant: 'warning btn-elasten-popup',
  okTitle: 'Đồng ý',
  cancelTitle: 'Hủy',
  hideHeaderClose: false,
  centered: true
};

export const S3_DIALOG_OPTION = {
  title: 'Xác nhận',
  size: 'md',
  buttonSize: 'sm',
  okVariant: 'elasten-popup',
  okTitle: 'Đồng ý',
  cancelTitle: 'Hủy',
  hideHeaderClose: false,
  centered: true
}

export const DEFAULT_LOADING_OPTION = {
  // Optional parameters
  container: null,
  canCancel: false
};
