export const SystemSettingModule = {
  namespaced: true,
  state: {
    programID: null,
    systemSetting: {
      onOff: false,
      spinnerImageUrl: '',
      indicatorImageUrl: '',
      thankText: {
        title: '',
        description: ''
      },
      offText: {
        title: '',
        description: ''
      }
    }
  },
  getters: {
    systemSetting(state) {
      return state.systemSetting;
    },
    programID(state) {
      return state.programID;
    }
  },
  mutations: {
    set(state, systemSetting) {
      state.systemSetting = systemSetting;
    },
    setProgramID(state, programID) {
      state.programID = programID;
    },
  },
  actions: {
    load(context, systemSetting) {
      context.commit('set', systemSetting);
    },
    setProgramID(context, programID) {
      context.commit('setProgramID', programID);
    },
  },
};
