import { PROGRAM_STEP } from '@/util/constant';
import router from '@/router';

const DEFAULT_FORM_VAL = {
  id: null,
  hologramCode: '',
  fullname: '',
  phoneNo: '',
  identityNo: '',
  addressBuy: null,
  addressGetPrize: null,
  provinceId: null,
  districtId: null,
  wardId: null,
  street: null
};

const DEFAULT_FORM_STATE = {
  disabled: false,
  screenshotUri: null,
  screenShot: null,
  selectedScreenshot: null,
  provinces: [],
  districts: [],
  wards: []
};

export const TicketModule = {
  namespaced: true,
  state: {
    ticket: null,
    phoneNumber: null,
    form_temp: {
      ...DEFAULT_FORM_VAL,
    },
    form_state: {
      ...DEFAULT_FORM_STATE,
    },
    step: PROGRAM_STEP.INITIAL,
    resultScreenCaptured: false,
  },
  getters: {
    ticket(state) {
      return state.ticket;
    },
    form(state) {
      return state.form_temp;
    },
    form_id(state) {
      return state.form_temp.id;
    },
    form_hologramCode(state) {
      return state.form_temp.hologramCode;
    },
    form_fullname(state) {
      return state.form_temp.fullname;
    },
    form_phoneNo(state) {
      return state.form_temp.phoneNo;
    },
    form_identityNo(state) {
      return state.form_temp.identityNo;
    },
    form_addressBuy(state) {
      return state.form_temp.addressBuy;
    },
    form_addressGetPrize(state) {
      return state.form_temp.addressGetPrize;
    },
    form_provinceId(state) {
      return state.form_temp.provinceId;
    },
    form_districtId(state) {
      return state.form_temp.districtId;
    },
    form_wardId(state) {
      return state.form_temp.wardId;
    },
    form_street(state) {
      return state.form_temp.street;
    },
    form_disabled(state) {
      return state.form_state.disabled;
    },
    form_screenshotUri(state) {
      return state.form_state.screenshotUri;
    },
    form_screenshot(state) {
      return state.form_state.screenShot;
    },
    form_selectedScreenshot(state) {
      return state.form_state.selectedScreenshot;
    },
    form_provinces(state) {
      return state.form_state.provinces;
    },
    form_districts(state) {
      return state.form_state.districts;
    },
    form_wards(state) {
      return state.form_state.wards;
    },
    step(state) {
      return state.step;
    },
    resultScreenCaptured(state) {
      return state.resultScreenCaptured;
    },
  },
  mutations: {
    set(state, ticket) {
      state.ticket = ticket;
    },
    resetForm(state) {
      const provinces = state.form_state.provinces;
      const phoneNo = state.form_temp.phoneNo;
      state.form_temp = {
        ...DEFAULT_FORM_VAL,
        phoneNo: phoneNo
      };
      state.form_state = {
        ...DEFAULT_FORM_STATE,
        provinces: provinces
      };
    },
    patchForm(state, payload) {
      state.form_temp = { ...DEFAULT_FORM_VAL, ...payload };
      state.form_state = { ...DEFAULT_FORM_STATE, disabled: payload.disabled, screenshotUri: payload.screenshotUri };
    },
    updateFormTemp(state, payload) {
      state.form_temp[payload[0]] = payload[1];
    },
    updateFormState(state, payload) {
      state.form_state[payload[0]] = payload[1];
    },
    changeStep(state, step) {
      state.step = step;
    },
    clear(state) {
      state.ticket = null;
    },
    markResultScreenCaptured(state, value) {
      state.resultScreenCaptured = value;
    },
  },
  actions: {
    pick(context, ticket) {
      context.commit('set', ticket);
    },
    patchForm(context, customerInfo) {
      context.commit('patchForm', customerInfo);
    },
    async goToStep(context, step) {
      context.commit('changeStep', step);
      const programID = context.rootGetters['systemSetting/programID'];
      switch (step) {
        case PROGRAM_STEP.INITIAL:
          await router.push({ path: `/${programID}/customer/ticket` });
          break;
        case PROGRAM_STEP.READY_TO_ROLL:
          context.commit('markResultScreenCaptured', false);
          await router.push({ path: `/${programID}/customer/rotation` });
          break;
        case PROGRAM_STEP.RESULT:
          await router.push({ path: `/${programID}/customer/result` });
          break;
        case PROGRAM_STEP.SUBMIT_INFO:
          await router.push({ path: `/${programID}/customer/info` });
          break;
        case PROGRAM_STEP.THANK:
          context.commit('resetForm');
          await router.push({ path: `/${programID}/customer/thank` });
          break;
        case PROGRAM_STEP.OFF:
          context.commit('resetForm');
          await router.push({ path: `/${programID}/customer/off` });
          break;
      }
    },
    remove(context) {
      context.commit('clear');
    },
    captureScreen(context) {
      context.commit('markResultScreenCaptured', true);
    },
    resetForm(context) {
      context.commit('resetForm');
    }
  },
};
