import DashboardLayout from '@/views/Layout/DashboardLayout.vue';
import AuthLayout from '@/views/Pages/AuthLayout.vue';
import NotFound from '@/views/NotFoundPage.vue';
import CustomerContent from '@/views/Layout/CustomerContent';

import { API } from '@/util/api';
import { ADMIN_URLS, CUSTOMER_URLS } from '@/util/endpoints';
import store from '@/store';
import { getAccessToken } from '@/util/storage-util';
import { PROGRAM_STEP } from '@/util/constant';
import AdminContent from '@/views/Layout/AdminContent';

const programs = [
  {
    name: 'Quà đẹp xinh mừng sinh nhật',
    programID: 's1'
  },
  {
    name: 'Sinh nhật vàng, nhận quà sang',
    programID: 's2'
  },
  {
    name: 'VQMM S3',
    programID: 's3'
  }
];

const routes = [
  {
    path: '/admin',
    name: 'Quản trị',
    redirect: '/admin/profile',
    component: DashboardLayout,
    beforeEnter: async (to, from, next) => {
      // reject the navigation
      if (getAccessToken() && !store.getters['user/user']) {
        await fetchUserInfo();
      }
      if (!store.getters['user/user']) {
        next({ path: '/auth/login' });
      } else {
        next();
      }
    },
    children: [
      ...(programs.map(program => ({
        path: program.programID,
        name: program.name,
        icon: 'ni ni-app',
        component: AdminContent,
        beforeEnter: async (to, from, next) => {
          // reject the navigation
          try {
            await store.dispatch('systemSetting/setProgramID', program.programID);
            try {
              const response = await API.get(CUSTOMER_URLS.GET_SYSTEM_SETTING, {
                headers: {
                  ProgramID: program.programID
                }
              });
              await store.dispatch('systemSetting/load', response.data);
            } catch (e) {
              console.error(e);
            }
            next();
          } catch (e) {
            console.error(e);
            next();
          }
        },
        children: [
          {
            path: 'prize-management',
            name: 'Quản lý quà tặng',
            icon: 'ni ni-box-2 text-primary',
            component: () => import('../views/Admin/PrizeManagement.vue')
          },
          {
            path: 'prize-distribution-management',
            name: 'Quản lý phân bổ quà',
            icon: 'ni ni-chart-pie-35 text-primary',
            component: () => {
              if (program.programID === programs[0].programID) {
                return import('../views/Admin/PrizeDistributionManagement1.vue');
              } else {
                return import('../views/Admin/PrizeDistributionManagement2.vue');
              }
            }
          },
          {
            path: 'customer-management',
            name: 'Quản lý khách hàng',
            icon: 'ni ni-circle-08 text-primary',
            component: () => {
              if (program.programID === programs[0].programID) {
                return import('../views/Admin/CustomerManagement1.vue');
              } else {
                if (program.programID === 's3') {
                  return import('../views/Admin/CustomerManagement3.vue');
                }
                return import('../views/Admin/CustomerManagement2.vue');
              }
            }
          },
          {
            path: 'export',
            name: 'Xuất báo cáo',
            icon: 'ni ni-money-coins text-primary',
            component: () => import('@/views/Admin/ExportManagement.vue')
          },
          {
            path: 'system-setting',
            name: 'Cấu hình hệ thống',
            icon: 'ni ni-settings text-primary text-md',
            component: () => import('../views/Admin/SystemSetting.vue')
          }
        ]
      }))),
      {
        path: 'change-password',
        name: 'Đổi mật khẩu',
        icon: 'ni ni-key-25',
        component: () => import('../views/Admin/ChangePassword.vue')
      },
      {
        path: 'icons',
        name: 'Biểu tượng',
        hidden: true,
        icon: 'ni ni-planet text-blue',
        component: () => import('../views/Icons.vue')
      },
      {
        path: 'profile',
        name: 'Hồ sơ',
        hidden: true,
        icon: 'ni ni-single-02 text-yellow',
        component: () => import('../views/Pages/UserProfile.vue')
      },
      {
        path: '*',
        component: () => import('../views/NotFoundPage.vue')
      }
    ]
  },
  {
    path: '/auth',
    redirect: '/auth/login',
    component: AuthLayout,
    children: [
      {
        path: 'login',
        name: 'login',
        icon: 'ni ni-key-25 text-info',
        beforeEnter: async (to, from, next) => {
          // reject the navigation
          next();
        },
        component: () => import('../views/Pages/Login.vue')
      },
      {
        path: 'register',
        name: 'register',
        icon: 'ni ni-circle-08 text-pink',
        component: () => import('../views/Pages/Register.vue')
      },
      { path: '', redirect: '/auth/login' }
    ]
  },
  // { path: '/home', name: 'home', component: Home },
  ...(['s1', 's2', 's3'].map(programID => ({
    path: `/${programID}/customer`,
    redirect: `/${programID}/customer/ticket`,
    component: CustomerContent,
    beforeEnter: async (to, from, next) => {
      // reject the navigation
      try {
        await store.dispatch('systemSetting/setProgramID', programID);
        const r = document.querySelector(':root');
        for (const mh of ['', '-mh1']) {
          r.style.setProperty(`--bg-375x500${mh}`, `url('${process.env.VUE_APP_API_BASE_URL}/api/resource/${programID}/backgrounds/bg-375x500${mh}.webp')`);
          r.style.setProperty(`--bg-480x854${mh}`, `url('${process.env.VUE_APP_API_BASE_URL}/api/resource/${programID}/backgrounds/bg-480x854${mh}.webp')`);
          r.style.setProperty(`--bg-480x960${mh}`, `url('${process.env.VUE_APP_API_BASE_URL}/api/resource/${programID}/backgrounds/bg-480x960${mh}.webp')`);
          r.style.setProperty(`--bg-854x480${mh}`, `url('${process.env.VUE_APP_API_BASE_URL}/api/resource/${programID}/backgrounds/bg-854x480${mh}.webp')`);
          r.style.setProperty(`--bg-1024x576${mh}`, `url('${process.env.VUE_APP_API_BASE_URL}/api/resource/${programID}/backgrounds/bg-1024x576${mh}.webp')`);
          r.style.setProperty(`--bg-1600x900${mh}`, `url('${process.env.VUE_APP_API_BASE_URL}/api/resource/${programID}/backgrounds/bg-1600x900${mh}.webp')`);
        }
        next();
      } catch (e) {
        console.error(e);
        next();
      }
    },
    children: [
      {
        path: 'ticket',
        name: 'Nhập mã',
        component: () => import('../views/Customer/Ticket.vue'),
        beforeEnter: async (to, from, next) => {
          // reject the navigation
          try {
            const response = await API.get(CUSTOMER_URLS.CHECK_PROGRAM_ENABLED, {
              headers: {
                ProgramID: programID
              }
            });
            if (response.data) {
              await store.commit('ticket/changeStep', PROGRAM_STEP.INITIAL);
              next();
            } else {
              await store.commit('ticket/changeStep', PROGRAM_STEP.OFF);
              next({ path: `/${programID}/customer/off` });
            }

          } catch (e) {
            console.error(e);
            next();
          }
        },
      },
      {
        path: 'rotation',
        name: 'Quay quà tặng',
        component: () => import('../views/Customer/Rotation.vue')
      },
      {
        path: 'result',
        name: 'Thông báo trúng giải',
        component: () => import('../views/Customer/Result.vue')
      },
      {
        path: 'info',
        name: 'Thông tin khách hàng',
        component: () => {
          const index = programs.findIndex(program => program.programID === programID);
          return import(`@/views/Customer/Info${index+1}.vue`);
        }
      },
      {
        path: 'thank',
        name: 'Cảm ơn',
        component: () => import('../views/Customer/Thank.vue')
      },
      {
        path: 'off',
        name: 'Chương trình quay thưởng đã kết thúc',
        component: () => import('../views/Customer/Off.vue'),
        beforeEnter: async (to, from, next) => {
          // reject the navigation
          try {
            const response = await API.get(CUSTOMER_URLS.CHECK_PROGRAM_ENABLED, {
              headers: {
                ProgramID: programID
              }
            });
            if (response.data) {
              await store.commit('ticket/changeStep', PROGRAM_STEP.INITIAL);
              next({ path: `/${programID}/customer/ticket` });
            } else {
              await store.commit('ticket/changeStep', PROGRAM_STEP.OFF);
              next();
            }

          } catch (e) {
            console.error(e);
            next();
          }
        },
      }
    ]
  }))),
  {
    path: '/s1',
    redirect: '/s1/customer',
  },
  {
    path: '/s2',
    redirect: '/S2/customer',
  },
  {
    path: '/s3',
    redirect: '/s3/customer',
  },
  {
    path: '/customer/ticket',
    redirect: '/s1/customer/ticket',
  },
  {
    path: '/',
    redirect: '/s3/customer/ticket',
  },
  { path: '*', component: NotFound }
];

async function fetchUserInfo() {
  try {
    const response = await API.get(ADMIN_URLS.INFO);
    await store.dispatch('user/login', response.data);
  } catch (e) {
    console.error(e);
    await store.dispatch('user/logout');
  }
}

export default routes;
