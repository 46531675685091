<template>
  <b-nav-item
    :is="baseComponent"
    v-if="!link.hidden"
    class="nav-item"
  >
    <a
      v-if="isMenu"
      class="sidebar-menu-item nav-link"
      :aria-expanded="!collapsed"
      data-toggle="collapse"
      @click.prevent="collapseMenu"
    >
      <i
        v-if="link.icon"
        :class="link.icon"
      />
      <span class="nav-link-text">{{ link.name }} <b class="caret" /></span>
    </a>
    <a
      v-else
      class="sidebar-menu-item nav-link nav-link-leaf"
      :class="{ active: isActive }"
      :href="fullLink"
      @click="closeSidebar"
    >
      <i
        v-if="link.icon"
        :class="link.icon"
      />
      <span class="nav-link-text">{{ link.name }} <b class="caret" /></span>
    </a>

    <collapse-transition>
      <div
        v-if="isMenu"
        v-show="!collapsed"
        class="collapse show"
      >
        <ul class="nav nav-sm flex-column">
          <sidebar-item
            v-for="child in link.children"
            :key="child.path"
            :link="child"
            :prefix="fullLink"
          />
        </ul>
      </div>
    </collapse-transition>
  </b-nav-item>
</template>
<script>
import { CollapseTransition } from 'vue2-transitions';
import EventBus from '@/util/event-bus';

export default {
  name: 'SidebarItem',
  components: {
    CollapseTransition
  },
  inject: {
    autoClose: {
      default: true
    }
  },
  props: {
    prefix: {
      type: String,
      default: () => {
        return '';
      },
    },
    link: {
      type: Object,
      default: () => {
        return {
          name: '',
          path: '',
          children: []
        };
      },
      description:
        'Sidebar link. Can contain name, path, icon and other attributes. See examples for more info'
    }
  },
  data() {
    return {
      collapsed: true
    };
  },
  computed: {
    baseComponent() {
      return 'li';
    },
    fullLink() {
      return this.prefix + '/' + this.link.path;
    },
    isMenu() {
      return this.link.children?.length > 0;
    },
    isActive() {
      if (this.$route && this.$route.path) {
        if (!this.isMenu) {
          return this.$route.path.startsWith(this.link.path);
        }
      }
      return false;
    }
  },
  mounted() {
    if (this.link.collapsed !== undefined) {
      this.collapsed = this.link.collapsed;
    }
    if (this.isActive && this.isMenu) {
      this.collapsed = false;
    }
  },
  destroyed() {
  },
  methods: {
    collapseMenu() {
      this.collapsed = !this.collapsed;
    },
    closeSidebar() {
      EventBus.$emit('nav', 'close');
    }
  }
};
</script>
<style>
.sidebar-menu-item {
  cursor: pointer;
}
.nav-link-leaf:after {
  display: none !important;
}

.nav-link-leaf.active {
  background: beige;
}
</style>
