import BaseInput from '@/components/ui/Inputs/BaseInput.vue';
import BaseDropdown from '@/components/ui/BaseDropdown.vue';
import Card from '@/components/ui/Cards/Card.vue';
import Modal from '@/components/ui/Modal.vue';
import StatsCard from '@/components/ui/Cards/StatsCard.vue';
import BaseButton from '@/components/ui/BaseButton.vue';
import Badge from '@/components/ui/Badge.vue';
import BaseCheckbox from '@/components/ui/Inputs/BaseCheckbox.vue';
import BaseRadio from '@/components/ui/Inputs/BaseRadio';
import BaseProgress from '@/components/ui/BaseProgress';
import BasePagination from '@/components/ui/BasePagination';
import BaseAlert from '@/components/ui/BaseAlert';
import BaseNav from '@/components/ui/Navbar/BaseNav';
import BaseHeader from '@/components/ui/BaseHeader';
import { ValidationProvider, ValidationObserver } from 'vee-validate';

/**
 * You can register global components here and use them as a plugin in your main Vue instance
 */

const GlobalComponents = {
  install(Vue) {
    Vue.component(Badge.name, Badge);
    Vue.component(BaseAlert.name, BaseAlert);
    Vue.component(BaseButton.name, BaseButton);
    Vue.component(BaseCheckbox.name, BaseCheckbox);
    Vue.component(BaseHeader.name, BaseHeader);
    Vue.component(BaseInput.name, BaseInput);
    Vue.component(BaseDropdown.name, BaseDropdown);
    Vue.component(BaseNav.name, BaseNav);
    Vue.component(BasePagination.name, BasePagination);
    Vue.component(BaseProgress.name, BaseProgress);
    Vue.component(BaseRadio.name, BaseRadio);
    Vue.component(Card.name, Card);
    Vue.component(Modal.name, Modal);
    Vue.component(StatsCard.name, StatsCard);
    Vue.component('ValidationProvider', ValidationProvider);
    Vue.component('ValidationObserver', ValidationObserver);
  }
};

export default GlobalComponents;
