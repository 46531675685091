import { configure, extend } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';

export const NUMBER_REGEX = new RegExp('^\\d+$');

export const PASSWORD_REGEX = new RegExp(
  '^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#&()–\\[{}\\]:;\',\?\*~\$\^\+=<>]).{8,20}$');

export const PHONE_NUMBER_REGEX = /(((\+|)84)|0)(2|3|5|7|8|9)+([0-9]{8})\b/;

const ValidationPlugin = {
  // eslint-disable-next-line no-unused-vars
  install(Vue, options) {
    Object.keys(rules).forEach(rule => {
      extend(rule, rules[rule]);
    });

// with typescript
    for (let [rule, validation] of Object.entries(rules)) {
      extend(rule, {
        ...validation,
      });
    }
    extend('required', {
      validate(value) {
        return {
          required: true,
          valid: ['', null, undefined].indexOf(value) === -1,
        };
      },
      computesRequired: true,
      message: 'Vui lòng nhập {_field_}',
    });
    extend('phone_number', value => {
      if (PHONE_NUMBER_REGEX.test(value)) {
        return true;
      }
      return '{_field_} không đúng định dạng';
    });
    extend('positive_int', value => {
      if (NUMBER_REGEX.test(value) && Number(value) >= 0) {
        return true;
      }
      return '{_field_} là kiểu số nguyên dương';
    });
    extend('password', value => {
      if (PASSWORD_REGEX.test(value)) {
        return true;
      }
      return 'Mật khẩu có độ dài 8 - 20 ký tự và phải bao gồm chữ in hoa, in thường, chữ số và ký tự đặc biệt';
    });
    extend('regex',  {
      message: '{_field_} không hợp lệ'
    });
    configure({
      classes: {
        valid: 'is-valid',
        invalid: 'is-invalid',
        dirty: ['is-dirty', 'is-dirty'] // multiple classes per flag!
      },
    });
  },
};

export default ValidationPlugin;
