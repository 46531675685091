<template>
  <ValidationProvider
    v-slot="{ classes, errors }"
    :vid="vid"
    :name="name"
    :rules="rules"
    :mode="mode"
  >
    <b-form-file
      ref="fileRef"
      :value="value"
      :size="size"
      :multiple="multiple"
      :accept="accept"
      :state="null"
      :disabled="disabled"
      :class="customClass | toStringClass(classes)"
      :placeholder="placeholder"
      :drop-placeholder="dropPlaceholder"
      :file-name-formatter="format"
      @input="onInput"
      @change="onChange"
    />
    <small
      class="text-danger text-break"
      style="white-space: normal"
    >
      {{ errors[0] }}
    </small>
  </ValidationProvider>
</template>

<script>
export default {
  name: 'ValidationFormFile',
  props: {
    id: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      default: ''
    },
    dropPlaceholder: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: 'sm'
    },
    vid: {
      type: String,
      default: ''
    },
    rules: {
      type: [String, Object],
      default: ''
    },
    mode: {
      type: String,
      default: 'aggressive'
    },
    value: {
      type: [File, Array],
      required: false,
      default: () => null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    customStyle: {
      type: String,
      default: ''
    },
    customClass: {
      type: String,
      default: ''
    },
    accept: {
      type: String,
      default: '*'
    }
  },
  methods: {
    format(files, filesTraversed, names) {
      return names[0];
    },
    refreshFileName(file) {
      this.$refs.fileRef.setFiles(file);
    },
    onInput(value) {
      this.$emit('input', value);
    },
    onChange(value) {
      this.$emit('change', value);
    }
  }
};
</script>

<style scoped>

</style>
