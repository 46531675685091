export const TicketStatus = {
  UNUSED: 0,
  USED: 1
};

export const PROGRAM_STEP = {
  INITIAL: 1,
  READY_TO_ROLL: 2,
  RESULT: 3,
  SUBMIT_INFO: 4,
  THANK: 5,
  OFF: 6
};

export const FILE_REGEX = /filename="(?<filename>.*)"/;
