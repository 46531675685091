import { UserModule } from '@/store/user.js';
import { TicketModule } from '@/store/ticket.js';
import Vue from 'vue';
import Vuex from 'vuex';
import { SystemSettingModule } from '@/store/system-setting';


Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    loading: false
  },
  getters: {
    loading(state) {
      return state.loading;
    },
  },
  mutations: {
    switch(state, val) {
      state.loading = val;
    }
  },
  actions: {
    load(context, val) {
      context.commit('switch', val);
    },
  },
  modules: {
    user: UserModule,
    ticket: TicketModule,
    systemSetting: SystemSettingModule
  }
});

export default store;
