export const UserModule = {
  namespaced: true,
  state: {
    user: null,
  },
  getters: {
    user(state) {
      return state.user;
    },
  },
  mutations: {
    set(state, user) {
      state.user = user;
    },
    clear(state) {
      state.user = null;
    },
  },
  actions: {
    login(context, user) {
      context.commit('set', user);
    },
    logout(context) {
      context.commit('clear');
    },
  },
};
