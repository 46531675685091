<template>
  <base-nav
    container-classes="container-fluid"
    class="navbar-top navbar-expand"
    :class="{'navbar-dark': type === 'default'}"
  >
    <a
      :href="`#${$route.path}`"
      aria-current="page"
      class="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block active router-link-active"
    > {{ $route.name }} </a>
    <!-- Navbar links -->
    <b-navbar-nav class="align-items-center ml-md-auto">
      <!-- This item dont have <b-nav-item> because item have data-action/data-target on tag <a>, wich we cant add -->
      <li class="nav-item d-sm-none">
        <a
          class="nav-link"
          href="#"
          data-action="search-show"
          data-target="#navbar-search-main"
        >
          <i class="ni ni-zoom-split-in" />
        </a>
      </li>
    </b-navbar-nav>
    <b-navbar-nav class="align-items-center ml-auto ml-md-0">
      <!--      <b-form-->
      <!--        id="navbar-search-main"-->
      <!--        class="navbar-search form-inline mr-sm-3"-->
      <!--        :class="{'navbar-search-dark': type === 'default', 'navbar-search-light': type === 'light'}"-->
      <!--      >-->
      <!--        <b-form-group class="mb-0">-->
      <!--          <b-input-group class="input-group-alternative input-group-merge">-->
      <!--            <b-form-input-->
      <!--              placeholder="Tìm kiếm"-->
      <!--              type="text"-->
      <!--            />-->

      <!--            <div class="input-group-append">-->
      <!--              <span class="input-group-text"><i class="fas fa-search" /></span>-->
      <!--            </div>-->
      <!--          </b-input-group>-->
      <!--        </b-form-group>-->
      <!--      </b-form>-->
      <base-dropdown
        menu-on-right
        class="nav-item"
        tag="li"
        title-tag="a"
        title-classes="nav-link pr-0"
      >
        <a
          slot="title-container"
          href="#"
          class="nav-link pr-0"
          @click.prevent
        >
          <b-media
            no-body
            class="align-items-center"
          >
            <span class="avatar avatar-sm rounded-circle">
              <img
                alt="Image placeholder"
                src="img/theme/team-1.jpg"
              >
            </span>
            <b-media-body class="ml-2 d-none d-lg-block">
              <span class="mb-0 text-sm  font-weight-bold">{{ user ? user.username : '' }}</span>
            </b-media-body>
          </b-media>
        </a>

        <template v-if="true">
          <!--          <b-dropdown-header class="noti-title">-->
          <!--            <h6 class="text-overflow m-0">-->
          <!--              Welcome!-->
          <!--            </h6>-->
          <!--          </b-dropdown-header>-->
          <!--          <b-dropdown-item >-->
          <!--            <i class="ni ni-single-02" />-->
          <!--            <span>Hồ sơ</span>-->
          <!--          </b-dropdown-item>-->
          <!--          <b-dropdown-item href="#!">-->
          <!--            <i class="ni ni-settings-gear-65" />-->
          <!--            <span>Cài đặt</span>-->
          <!--          </b-dropdown-item>-->
          <b-dropdown-item to="/admin/change-password">
            <i class="ni ni-key-25" />
            <span>Đổi mật khẩu</span>
          </b-dropdown-item>
          <!--          <b-dropdown-item href="#!">-->
          <!--            <i class="ni ni-support-16" />-->
          <!--            <span>Hỗ trợ</span>-->
          <!--          </b-dropdown-item>-->
          <!--          <div class="dropdown-divider" />-->
          <b-dropdown-item
            @click="logout"
          >
            <i class="ni ni-user-run" />
            <span>Đăng xuất</span>
          </b-dropdown-item>
        </template>
      </base-dropdown>
    </b-navbar-nav>
  </base-nav>
</template>
<script>
import { CollapseTransition } from 'vue2-transitions';
import { BaseNav, Modal } from '@/components';
import { mapGetters } from 'vuex';
import router from '@/router';
import { DEFAULT_LOADING_OPTION } from '@/util/dialog-options';
import { removeToken } from '@/util/storage-util';

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    CollapseTransition,
    BaseNav,
    // eslint-disable-next-line vue/no-unused-components
    Modal
  },
  props: {
    type: {
      type: String,
      default: 'default', // default|light
      description: 'Look of the dashboard navbar. Default (Green) or light (gray)'
    }
  },
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchModalVisible: false,
      searchQuery: ''
    };
  },
  computed: {
    ...mapGetters({
      user: 'user/user'
    }),
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    }
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    logout() {
      removeToken();
      this.$store.dispatch('user/logout');
      let loader = this.$loading.show(DEFAULT_LOADING_OPTION);
      setTimeout(() => {
        loader.hide();
        router.push({ path: '/auth/login' });
      }, 1500);
    }
  }
};
</script>
