export const BASE_TOAST_OPTION = {
  solid: true,
  appendToast: true,
  autoHideDelay: 4000,
  // noAutoHide: true,
  noHoverPause: true,
};

export const SUCCESS_DIALOG_OPTION = {
  ...BASE_TOAST_OPTION,
  title: 'Thông báo',
  variant: 'success',
};

export const DANGER_TOAST_OPTION = {
  ...BASE_TOAST_OPTION,
  title: 'Lỗi',
  variant: 'danger',
};

export const WARNING_TOAST_OPTION = {
  ...BASE_TOAST_OPTION,
  title: 'Lời nhắc',
  variant: 'warning',
};

export const INFO_TOAST_OPTION = {
  ...BASE_TOAST_OPTION,
  title: 'Lưu ý',
  variant: 'info',
};
